import React from "react";
import Img1 from "./../../images/plantcurtains/dreifaltigkeit-1.png";
import Img2 from "./../../images/plantcurtains/dreifaltigkeit-2.png";
import Img3 from "./../../images/plantcurtains/dreifaltigkeit-3.png";
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Plantcurtains = () => {
  return (
    <div className=" bg-green-default unprofessionalcustomcsstofixmargintop">
      <div className="section-container">
        <div className="flex ">
          <h1 className="heading">Plant curtains for everybody!</h1>
        </div>
        <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 md:pt-20 pt-10 gap-8">
        <StaticImage 
        src="./../../images/plantcurtains/dreifaltigkeit-1.png" 
        title="Challenge"
        alt="Cities become more and at the same time bigger. Glass facades increase. There is no solution for vegetating glass in a functional way"
        placeholder="blurred" />  
        <StaticImage 
        title="Idea"
        alt="A free DIY design empowers everybody worldwide to green their individual parts of the skyline by themselves. The instructions are for free for everybody to download, use, adapt, improve, and further elaborate the idea"
        src="./../../images/plantcurtains/dreifaltigkeit-2.png" 
        placeholder="blurred" />  
        <StaticImage 
        title="Effect"
        alt="Every glass facade worldwide could become green, enhance biodiversity, produce oxygen and provide agricultural potential"
        src="./../../images/plantcurtains/dreifaltigkeit-3.png" 
        placeholder="blurred" />  
        </div>
      </div>
    </div>
  );
};

export default Plantcurtains;
const curtains = [
  {
    id: 1,
    title: "Challenge",
    description: "desc",
    alt:"test",
    img: Img1,
  },
  {
    id: 2,
    title: "Idea",
    description: "desc",
    img: Img2,
  },
  {
    id: 3,
    title: "Effect",
    description: "desc",
    img: Img3,
  },
];
