import React from "react";
import Videosection from "./Video";
import Plantcurtains from "./Plantcurtains";
import Havelook from "./Havelook";
import Gallery from "./Gallery";
import GalleryNew from "./GalleryNew";
import OurVision from "./OurVision";
import Perspective from "./Perspective";
import Team from "./Team";
import Interested from "./Interested";
import Layout from "./../Layout/Layout";



const Index = () => {
  return (
    <Layout>
      <Videosection />
      <Plantcurtains />
      <Havelook />
      <GalleryNew />
      <OurVision />
      <Perspective />
      <Interested />
      <Team />
     
    </Layout>
  );
};

export default Index;
