import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"


const Perspective = () => {
  return (
    <div className=" bg-green-default">
      <div className="section-container">
        <div className="flex ">
          <h1 className="heading">Perspective</h1>
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 md:pt-20 pt-0 gap-12">
              <div>
                <div className="mt-12">
                  {" "}
                  <a href="https://creativecommons.org/licenses/by/4.0/deed.en" target="__blank">
                  <StaticImage
                  src="./../../images/perspective/creative-commons-785334_640-1.png"
                  alt="Creative Commons Logo"
                  placeholder="blurred" />
                  </a>
                </div>
                <div className="bg-white my-8 h-2/5 p-5">
                  <p className="para-graph break-words">We believe in the power of open source: Non-commercially copyings and adaptations are strongly encouraged and supported by us</p>
                </div>
              </div>
              <div>
                <div className="mt-12">
                  {" "}
                  <a href="https://www.dlg.org/de/landwirtschaft/veranstaltungen/urban-farming/" target="__blank">
                  <StaticImage
                  src="./../../images/perspective/dlg_logo_header-1.jpg"
                  alt="DLG Logo"
                  placeholder="blurred" />
                  </a>
                </div>
                <div className="bg-white my-8 h-2/5 p-5">
                  <p className="para-graph break-words">The German Farming Association (DLG) named us one of 2020’s most innovative ‘Urban Farming’ concepts</p>
                </div>
              </div>
              <div>
                <div className="mt-12">
                  {" "}
                  <a href="mailto:hello@thegreenskylineinitiative.com" target="__blank">
                  <StaticImage
                  src="./../../images/perspective/crowdfunding_logo.png"
                  alt="Picture of hands supporting each other"
                  placeholder="blurred" />
                  </a>
                </div>
                <div className="bg-white my-8 h-2/5 p-5">
                  <p className="para-graph break-words">For more free research we need more financial partners. If you are one, get in touch at hello@thegreenskylineinitiative.com</p>
                </div>
              </div>
              <div>
                <div className="mt-12">
                  {" "}
                  <a href="https://www.indiegogo.com/projects/bioblinds-the-green-skyline-initiative" target="__blank">
                  <StaticImage
                  src="./../../images/perspective/indiegogo-logo.png"
                  alt="Indiegogo logo"
                  placeholder="blurred" />
                  </a>
                </div>
                <div className="bg-white my-8 h-2/5 p-5">
                  <p className="para-graph break-words">If you want to learn more about our successful crowdfunding campaign, please click on the Indiegogo logo above</p>
                </div>
              </div>
              <div>
                <div className="mt-12">
                  {" "}
                  <Link to="/bioblinds-2nd-edition-cowshed">
                  <StaticImage
                  src="./../../images/perspective/gruendau_logo.jpg"
                  alt="Logo of the farm of Manuel Schneider"
                  placeholder="blurred" />
                  </Link>
                </div>
                <div className="bg-white my-8 h-2/5 p-5">
                  <p className="para-graph break-words">Manuel Schneider’s 800 cows will soon be at least as good as the members of the international Soho Houses in their cowsheds</p>
                </div>
              </div>
              <div>
                <div className="mt-12">
                  {" "}
                  <a href="https://www.gabot.de/ansicht/compost-urbain-gewinnt-gardena-garden-award-405675.html" target="__blank">
                  <StaticImage
                  src="./../../images/perspective/gardena.png"
                  alt="GARDENA award logo"
                  placeholder="blurred" />
                  </a>
                </div>
                <div className="bg-white my-8 h-2/5 p-5">
                  <p className="para-graph break-words">We took the third place in the GARDENA garden award that recognizes sustainable solutions for the future of the 'digital garden'</p>
                </div>
              </div>
        </div>
      </div>
    </div>
  );
};

export default Perspective;
