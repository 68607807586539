import React from "react";
import Index from "../components/Home";
import {BackTop} from "antd";
import {AiOutlineToTop} from "react-icons/ai";
import { Helmet } from "react-helmet";



const HomePage = () => {
  return (
    <div>
            <Helmet>
          <meta charSet="utf-8" />
          <title>Plant curtains for everybody! - Bioblinds - The Green Skyline Initiative</title>
          <link rel="canonical" href="https://thegreenskylineinitiative.com/" />
          <script src="https://swetrix.org/swetrix.js" defer></script>
        </Helmet>
      <Index />
      <BackTop>
        <div className="relative">
          <div className="bg-white shadow-2xl w-14 h-14 rounded-full md:absolute md:-right-16">
            <AiOutlineToTop className="text-green-default w-14 h-14 p-2" />
          </div>
        </div>
      </BackTop>
    </div>
  );
};

export default HomePage;
