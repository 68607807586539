import React from "react";
import InterImg from "./../../images/closed.png";
import {Link} from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Interested = () => {
  return (
    <div className=" bg-green-default">
      <div className="max-w-7xl mx-auto px-1.5 md:px-10 pt-20 ">
        <div className=" ">
          <div className="bg-white md:w-2/4  w-11/12 m-auto px-8 md:px-12 py-10 pb-72 lg:py-20 lg:pb-96">
            <p className="text-black bg-white text-40 text-28 font-bold mb-14">
              Interested in puzzling your part of the skyline into green – by
              yourself? Start building your own Bioblinds.
            </p>
            <p className="text-black md:text-2xl text-xl font-bold mb-5">
              Keep updated and download ‘Bioblinds 1st Edition IVY’
            </p>
            <div className="swap bg-white">
            <Link to="/newsletter">
            <figure className="swap-on-hover mx-auto">
             <img className="swap-on-hover__front-image" src="https://thegreenskylineinitiative.com/files/closed-min.png" />
              <img className="swap-on-hover__back-image mx-auto" src="https://thegreenskylineinitiative.com/files/open-min.png" />
            </figure>
            </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Interested;
