import React from "react";

const Havelook = () => {
  return (
    <div className=" bg-green-default">
      <div className="section-container">
        <div className="flex ">
          <h1 className="heading pb-10">Please, have a look!</h1>
        </div>
        <div className="grid md:grid-cols-2 md:justify-start justify-center grid-col-1 lg:pt-10">
          {lookvideos.map((lookvideo) => {
            return (
              <div>
                <div className="vimeoframe">
                  <iframe
                    src={lookvideo.videosrc}
                    frameborder="0"
                    webkitAllowFullScreen
                    mozallowfullscreen
                    allowFullScreen
                    playsinline
                    width="100%"
                    height="350"
                  />
                </div>
                <div className="lg:mt-5 text-white text-base">
                  <p>{lookvideo.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Havelook;
const lookvideos = [
  {
    id: 1,
    title: "title",
    description: "Jan explaining the idea and concept behind ‘Bioblinds’",
    alt: "Jan explaining the idea and concept behind ‘Bioblinds’",
    videosrc:
      "https://player.vimeo.com/video/372155680?h=72abf54553"
  },
  {
    id: 2,
    title: "title",
    description: "Sketch of the basic opening mechanism",
    alt: "Sketch of the basic opening mechanism",
    videosrc:
      "https://player.vimeo.com/video/401881244?h=ffc5e7ddeb"
  }
];
