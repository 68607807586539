import React from "react";
import Vimeo from "react-vimeo-embed";
 


const Videosection = () => {
  return (
    <div className=" bg-green-default">
      <div className="max-w-7xl  mx-auto md:pt-20 sm:pt-20 pt-16 pb-1">
        <div className="flex justify-center text-center ">
        <div className="responsive-video px-10">
        <Vimeo
          video="https://player.vimeo.com/video/403400503?h=daf81e5d38"
          poster="https://www.w3schools.com/images/w3schools_green.jpg"
          width="1200"
          autoplay
          className="responsive-video"
          muted
          controls={false}
          loop
          />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Videosection;



