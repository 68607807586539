import { StaticImage } from "gatsby-plugin-image"
import React from "react";

const OurVision = () => {
  return (
    <div className=" bg-green-default">
      <div className="section-container">
        <div className="flex ">
          <h1 className="heading ">Our Vision: Green skylines everywhere!</h1>
        </div>
        <div className="text-black md:w-2/3 bg-white px-7 md:mt-20 mt-10 py-5">
          <p className=" para-graph ">
            Together with you and your friends, we want to disruptively change
            skylines, like a puzzle, into green skylines. For that we provide a
            first impulse with the ‘Bioblinds 1st Edition IVY‘ and the Bioblinds
            homepage www.bioblinds.com, as a construction manual hub. You can
            download the first edition focusing on ivy under ‘DIY-editions’ in
            the top right corner of this website!
          </p>
          <p className="para-graph">
            Everybody is invited to join our initiative, collaborate, contribute
            and participate in the research. Our utopian dream is an online
            configurator that collects all necessary data and provides it as
            individual construction plans and shopping lists, free of charge for
            every climate zone and in respect to diverse and local plants. We
            not only want to improve CO2 compensation, increase urban greenery
            and vegetate glass facades, but also allow for individual food
            production and foster biodiversity in urban environments.
          </p>
          <p className=" para-graph ">
            The idea originally is meant for social housings but can also be
            applied to every kind of architecture, inspiring new kinds of green,
            interactive buildings and city plannings. Just imagine the
            possibilities of individually harvested vertical fields for every
            kind of housing on this planet in a way that it actually can be
            handmade. For us this expresses ‘democratic freedom’ in a tangible
            way. That is why we have chosen 2020, the 30-year anniversary of the
            German Reunification, for the start of ‘Bioblinds – The Green
            Skyline Initiative‘.
          </p>
          <p className=" para-graph ">
            We are especially excited about working already on our second
            edition. That one will be for cowsheds. Yes, you heard that
            correctly. Cowsheds. 🙂
          </p>
          <div className=" para-graph">
            <h1 className="text-black">Let‘s jungle the cities together!</h1>
            <h1 className="text-black">Let‘s puzzle skylines green!</h1>
            <br /><StaticImage 
            title="Your 'Bioblinds' Team"
            height={30}
            alt="Your 'Bioblinds' Team"
            src="./../../images/home/vision/Team_Unterschrift.png" 
            placeholder="blurred" />
            <br /><br />
            <p>
              <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/812051038&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div className="SoundCloud1"><a href="https://soundcloud.com/janengel" title="janengel" target="_blank" className="SoundCloud2" rel="noopener noreferrer">janengel</a> · <a href="https://soundcloud.com/janengel/20200502-bioblinds-vision-text" className="SoundCloud2" title="20200502 Bioblinds Vision Text" target="_blank" rel="noopener noreferrer">20200502 Bioblinds Vision Text</a></div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurVision;
