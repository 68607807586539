import React from "react";
import Img1 from "./../../images/team/Tino.jpg";
import Img2 from "./../../images/team/carolin-e1585576101970.jpg";
import Img3 from "./../../images/team/Jan-e1585576203114.jpg";
import Img4 from "./../../images/team/Judith-e1585576153990.jpg";
import Img5 from "./../../images/team/IMG_0008-ConvertImage-scaled-e1583853200853-1024x1024-1.jpg";
import Img6 from "./../../images/team/Rudiger-e1585576181743.jpg";
import Img7 from "./../../images/team/Anne-e1585576127940.jpg";
import Img8 from "./../../images/team/brecht-eberhard-1024x1024-1.jpg";

import { StaticImage } from "gatsby-plugin-image"


const Team = () => {
  return (
    <div className=" bg-green-default">
      <div className="section-container">
        <div className=" ">
          <h1 className="heading md:mb-20 mb-10">Team</h1>
          <div className="bg-white lg:w-2/4 md:w-3/4">
            <p className="bg-white p-7 font-bold text-lg">
              The team behind ‘Bioblinds – The Green Skyline Initiative’ comes
              out of four generations and two countries.
            </p>
          </div>
        </div>
        <div className="team-grid">
          <div>
            <div className="w-full">
              {" "}
              <a href="https://www.tinoseubert.com/" target="__blank">
                <StaticImage
                  src="./../../images/team/Tino.jpg"
                  title="Tino Seubert"
                  alt="Picture of Tino Seubert"
                  placeholder="blurred" />
              </a>
            </div>
            <div className="bg-white p-5 mt-10">
              <p className="text-lg">Tino Seubert</p>
              <p className="text-lg">Production design</p>
            </div>
          </div>
          <div>
            <div className="w-full">
              {" "}
                <StaticImage
                  src="./../../images/team/carolin-e1585576101970.jpg"
                  title="Carolin Schreiner"
                  alt="Picture of Carolin Schreiner"
                  placeholder="blurred" />
            </div>
            <div className="bg-white p-5 mt-10">
              <p className="text-lg">Carolin Schreiner</p>
              <p className="text-lg">Office management</p>
            </div>
          </div>
          <div>
            <div className="w-full">
              {" "}
              <a href="http://janengel.berlin/" target="__blank">
                <StaticImage
                  src="./../../images/team/Jan-e1585576203114.jpg"
                  title="Jan Engel"
                  alt="Picture of Jan Engel"
                  placeholder="blurred" />
                  </a>
            </div>
            <div className="bg-white p-5 mt-10">
              <p className="text-lg">Jan Engel</p>
              <p className="text-lg">Initiator</p>
            </div>
          </div>
          <div>
            <div className="w-full">
              {" "}
                <StaticImage
                  src="./../../images/team/Judith-e1585576153990.jpg"
                  title="Judith Jacobs"
                  alt="Picture of Judith Jacobs"
                  placeholder="blurred" />
            </div>
            <div className="bg-white p-5 mt-10">
              <p className="text-lg">Judith Jacobs</p>
              <p className="text-lg">Cultural consultant</p>
            </div>
          </div>
          <div>
            <div className="w-full">
              {" "}
              <a href="https://www.linkedin.com/in/leon-bittner/" target="__blank">
                <StaticImage
                  src="./../../images/team/x80.jpg"
                  title="Leon Bittner"
                  alt="Picture of Leon Bittner"
                  placeholder="blurred" />
                  </a>
            </div>
            <div className="bg-white p-5 mt-10">
              <p className="text-lg">Leon Bittner</p>
              <p className="text-lg">Information Systems</p>
            </div>
          </div>
          <div>
            <div className="w-full">
              {" "}
              <a href="https://netmark.de/" target="__blank">
                <StaticImage
                  src="./../../images/team/Rudiger-e1585576181743.jpg"
                  title="Rüdiger Engel"
                  alt="Picture of Rüdiger Engel"
                  placeholder="blurred" />
                  </a>
            </div>
            <div className="bg-white p-5 mt-10">
              <p className="text-lg">Rüdiger Engel</p>
              <p className="text-lg">Business expert</p>
            </div>
          </div>
          <div>
            <div className="w-full">
              {" "}
                <StaticImage
                  src="./../../images/team/Anne-e1585576127940.jpg"
                  title="Anne Jacobs"
                  alt="Picture of Anne Jacobs"
                  placeholder="blurred" />
            </div>
            <div className="bg-white p-5 mt-10">
              <p className="text-lg">Anne Jacobs</p>
              <p className="text-lg">Communication expert</p>
            </div>
          </div>
          <div>
            <div className="w-full">
              {" "}
              <a href="https://www.spdfraktion.de/abgeordnete/brecht?wp=19" target="__blank">
                <StaticImage
                  src="./../../images/team/brecht-eberhard-1024x1024-1.jpg"
                  title="MdB Dr. Eberhard Brecht"
                  alt="Picture of Eberhard Brecht"
                  placeholder="blurred" />
                  </a>
            </div>
            <div className="bg-white p-5 mt-10">
              <p className="text-lg">MdB Dr. Eberhard Brecht</p>
              <p className="text-lg">Political ambassador</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Team;
const TeamGalleries = [
  {
    id: 1,
    title: "Tino Seubert",
    description: " Production design",
    img: Img1,
    url: "https://www.tinoseubert.com/",
  },
  {
    id: 2,
    title: "Carolin Schreiner",
    description: "Office management",
    img: Img2,
    url: "",
  },
  {
    id: 3,
    title: "Jan Engel ",
    description: "Initiator",
    img: Img3,
    url: "http://janengel.berlin/",
  },
  {
    id: 4,
    title: "Judith Jacobs ",
    description: "Cultural consultant",
    img: Img4,
    url: "",
  },
  {
    id: 5,
    title: "Leon Bittner ",
    description: "Information systems",
    img: Img5,
    url: "https://www.linkedin.com/in/leon-bittner/",
  },
  {
    id: 6,
    title: "Rüdiger Engel ",
    description: "Business Expert",
    img: Img6,
    url: "https://netmark.de/",
  },
  {
    id: 7,
    title: "Anne Jacobs ",
    description: "Communication expert",
    img: Img7,
    url: "",
  },

  {
    id: 8,
    title: "MdB Dr. Eberhard Brecht",
    description: "Political ambassador",
    img: Img8,
    url: "https://www.spdfraktion.de/abgeordnete/brecht?wp=19",
  },
];
